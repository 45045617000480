import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

//images
import abn from "../assets/images/credit-guide/icon-ABN.svg"
import address from "../assets/images/credit-guide/icon-address.svg"
import complaints from "../assets/images/credit-guide/icon-complaints.svg"
import creditcard from "../assets/images/credit-guide/icon-credit-card.svg"
import dispute from "../assets/images/credit-guide/icon-gavel.svg"
import legalname from "../assets/images/credit-guide/icon-legal-name.svg"
import policehat from "../assets/images/credit-guide/icon-police-hat.svg"
import call from "../assets/images/icon-phone.svg"
import mail from "../assets/images/icon-email.svg"
import location from "../assets/images/icon-location.svg"

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Oxygen Credit Guide & Privacy Statement | Oxygen - Home Loans Made Simple" />
      <Navbar />
      <PageBanner pageTitle="Oxygen Credit Guide & Privacy Statement" />
      <section className="container credit-guide-content pb-100">
        <div className="row">
          <div className="col-lg-12 col-12 pr-5">
            <div className="complaints-content-area mb-5">
              <p className="first-para">
                This credit guide provides you with the key information you need
                to know to make an informed and confident choice when engaging
                our services.
                <br />
                <br />
                We are licensed to arrange loans and leases under the National
                Consumer Credit Protection Act 2009 (NCCP Act). The NCCP Act
                regulates the activity of lending, leasing, and finance broking.
                <br />
                <br />
                This Privacy Policy was last updated on 10 December 2021.
                Version 2.2
              </p>
              <h3>Key Information</h3>
              <div className="contact-info-area mb-5">
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img src={legalname} alt="legal name icon" />
                    <h5 className="info-label">Our Legal Name</h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5 className="info-label">
                      Oxygen Broking Services Pty Ltd
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img src={abn} alt="ABN number icon" />
                    <h5 className="info-label">Our ABN</h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5 className="info-label">46 103 177 377</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img src={address} alt="address icon" />
                    <h5 className="info-label">Address</h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5 className="info-label">
                      Level 12, 92 Pitt St
                      <br />
                      Sydney NSW 2000
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img src={call} alt="call icon" />
                    <h5 className="info-label">Phone</h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5 className="info-label">1300 855 699</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img src={mail} alt="email icon" />
                    <h5 className="info-label">Email</h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5>
                      <a href="mailto:complaints@oxygen.com.au">
                        complaints@oxygen.com.au
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img src={creditcard} alt="credit licence icon" />
                    <h5 className="info-label">
                      Australian Credit Licence Number
                    </h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5 className="info-label">390351</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img
                      src={policehat}
                      alt="Internal Complaints Officer icon"
                    />
                    <h5 className="info-label">Internal Complaints Officer</h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5 className="info-label">
                      Complaints Officer
                      <br />
                      Oxygen Broking Services
                      <br />
                      Level 12, 92 Pitt St
                      <br />
                      Sydney NSW 2000
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 info-text">
                    <img
                      src={dispute}
                      alt="External Dispute Resolution Scheme icon"
                    />
                    <h5 className="info-label">
                      External Dispute Resolution Scheme
                    </h5>
                  </div>
                  <div className="col-lg-6 col-md-6 info-text">
                    <h5 className="info-label">
                      Australian Financial Complaints Authority
                      <br />
                      GPO Box 3<br />
                      Melbourne VIC 3001
                      <br />
                      <a
                        href="https://www.afca.org.au"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.afca.org.au
                      </a>
                      <br />
                      <a href="mailto:info@afca.org.au">info@afca.org.au</a>
                    </h5>
                  </div>
                </div>
              </div>
              <h4 className="heading-3">
                Suitability of Loans to Your Financial Objectives
              </h4>
              <p>
                We aim to provide you with information from a range of lenders
                and products / loans. Once you have chosen a loan that is
                suitable for you, we will help you obtain an approval
              </p>
              <p>
                The list below documents the 6 most commonly used Lenders by
                Oxygen. This does not reflect all of the financial institutions
                that Oxygen is able to conduct business through.
              </p>
              <h4 className="heading-3">Financial Institutions</h4>
              <p>(Previous financial year)</p>
              <div className="row">
                <div className="col-md-8 col-12">
                  <div className="rate-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <span className="title">Rank</span>
                          </th>
                          <th className="col-middle">
                            <span className="title">Lender</span>
                          </th>
                          <th className="col-middle">
                            <span className="title">
                              <i className="special-character">%</i> of Loan
                              Settled
                            </span>
                          </th>
                          <th className="empty-cell">
                            <span className="title"></span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <span className="data financial">1</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">
                              Commonwealth Bank
                            </span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">16.3</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="data financial">2</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">BankWest</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">11.8</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="data financial">3</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">
                              St George Bank
                            </span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">10.4</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="data financial">4</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">ANZ</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">9.0</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="data financial">5</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">
                              Macquarie Bank
                            </span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">8.1</span>
                          </td>
                        </tr>
                        <tr className="border-bottom-0">
                          <td>
                            <span className="data financial">6</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">Westpac</span>
                          </td>
                          <td className="col-middle">
                            <span className="data financial">5.7</span>
                          </td>
                        </tr>
                        <tr className="empty-cell border-0">
                          <td colspan="4">
                            <span className="data"></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <p>
                By law, before entering into a specific credit contract, we are
                obliged to conduct a Preliminary Credit Assessment to determine
                what kind of loans would be suitable for you. In consultation
                with you, we will explore and discuss with you your financial
                situation, financial objectives and borrowing needs before we
                determine which loan product may suit your requirements.
              </p>
              <p>
                For the purposes of the Preliminary Credit Assessment, we will
                need to ask you some questions in order to assess whether the
                loan or lease is not unsuitable.
              </p>
              <p>The law requires us to:</p>
              <p>
                <ul>
                  <li>
                    Make reasonable inquiries about your requirements and
                    objectives;
                  </li>
                  <li>
                    Make reasonable inquiries about your financial situation;
                  </li>
                  <li>
                    Take reasonable steps to verify that financial situation
                  </li>
                </ul>
              </p>
              <p>
                The assessment will be conducted prior to recommendation of a
                particular product. The assessment will involve collection and
                verification of financial information to determine the
                appropriate loan amount and the loan costs associated with
                entering a credit contract. This will ensure that your
                circumstances will be assessed appropriately and that the
                options suggested will not place you in financial hardship. Once
                completed, this Preliminary Credit Assessment is only valid for
                90 days. A copy of the Preliminary Credit Assessment will be
                available to you, on request - this will be available up to 7
                years after we provide you with credit assistance.
              </p>
              <p>
                Prior to the Preliminary Credit Assessment being con-ducted, we
                may provide you with Product summaries that highlight various
                key features and benefits of the product. We may also provide
                you with Product Comparison documents that allow you to compare
                the features and benefits of each product and assess the
                suitability to your requirements.
              </p>
              <h4 className="heading-3">
                Fees payable by you to third parties
              </h4>
              <p>
                When the credit application (loan) is submitted, you may need to
                pay the lender's application fee, valuation fees, or other fees
                that are associated with the loan application process, even if
                the loan is ultimately unsuccessful.
              </p>
              <h4 className="heading-3">Fees payable by you to the licensee</h4>
              <p>
                If a fee is payable by you, this will be disclosed in a Credit
                Quote that will be provided to you. If a Credit Quote is not
                supplied, this will indicate that we do not charge consumers any
                fees.
              </p>
              <h4 className="heading-3">Payments received by the licensee</h4>
              <p>
                Please take notice that the licensee may receive fees,
                commissions, or financial rewards from Lenders or Lessors in
                connection with any finance we arrange for you. These fees are
                not payable by you.
              </p>
              <p>
                The commission / brokerage amount depends on the amount of the
                finance and may vary from product to product. We can provide you
                with information about a reasonable estimate of those
                commissions and how the commission is worked out if you require.
              </p>
              <p>
                Commissions are paid based on a percentage of the loan balance
                that is drawn down which in most cases will be net of any
                amounts you hold in an offset account. The way commissions are
                calculated and paid to us by lenders may vary. By following the
                responsible lending requirements, we will ensure the loan
                recommended to you is not unsuitable for your situation and
                objectives.
              </p>
              <h4 className="heading-3">
                Fees payable by the licensee to third parties
              </h4>
              <p>
                We may pay fees to call centre companies, real estate agents,
                accountants, or lawyers and others for referring you to us.
                These referral fees are generally small amounts in accordance
                with usual business practice.
              </p>
              <p>
                These are not fees payable by you. On request you can obtain a
                reasonable estimate of the amount of the fee and how it is
                worked out.
              </p>
              <p>
                From time to time, we may also remunerate other parties through
                payments, rewards or benefits.
              </p>
              <h4 className="heading-3">Acting in your Best Interests</h4>
              <p>
                When providing credit assistance, we are obliged to act in your
                best interests (Best Interests Duty). This means that any
                products we recommend to you must be in your best interests, and
                the reasons for these recommendations will be documented,
                recorded, and explained and provided to you. In order to ensure
                your best interests have been met, we will assess what
                product(s) and what credit assistance will be in your best
                interests.
              </p>
              <p>
                This recommendation will include information about why this is
                in your best interests and how it is aligned to your needs and
                objectives.
              </p>
              <h4 className="heading-3">Tiered Servicing Arrangements</h4>
              <p>
                Through your broker’s relationships with lenders they may have
                access to tiered servicing arrangements. The benefits of this
                access to these arrangements can include faster processing,
                better information or greater levels of assistance provided for
                obtaining loan approval. Your broker will advise you of any
                tiered service arrangements that are in place with a particular
                lender that they have proposed at the time recommendations are
                made.
              </p>
              <p>
                Access to this program is not based solely on the volume of new
                or existing lending your broker’s customers have with each
                respective lender and does not entitle them to any additional
                commissions outside of what they have disclosed to you, any
                additional payments or preferential customer discounts.
              </p>
              <h4 className="heading-3">Gifts and Inducements</h4>
              <p>
                Your Oxygen broker is prohibited from accepting gifts or
                inducements over $350. Any benefit given to your broker greater
                than $100 and less than $350 will be recorded in a Gifts
                Register. A copy of your broker’s register is available to
                inspect on request. If you wish to inspect the register, please
                contact your broker.
              </p>
              <h4 className="heading-3">Referrals</h4>
              <p>
                We may receive obtain referrals from a range of sources,
                including accountants, financial planners, real estate agents
                and others. If you were introduced or referred to us, we may pay
                the referrer a commission, fee or remunerate them in other ways.
                Details of any commission or fees being paid to the referrer
                will be included in the Credit Proposal Disclosure document
                provided to you.
              </p>
              <h4 className="heading-3">Credit & Valuation Reports</h4>
              <p>
                We may be provided access to free services such as credit report
                and valuations from our lenders. Details of any relevant
                services we may receive because of a recommendation we make to
                you, will be included in the Credit Proposal Disclosure document
                we provide to you.
              </p>
              <h4 className="heading-3">Multiple Roles</h4>
              <p>
                I may act in other roles or capacities related to the credit
                assistance provided to you. Each business activity is separate
                however I may be paid remuneration in my other business
                activities as a result of the credit assistance provided to you.
                Further details any relevant remuneration we may receive because
                of a recommendation we make to you, will be included in the
                Credit Proposal Disclosure document we provide to you.
              </p>
              <p>
                Where I am unable to act in more than one capacity, I will refer
                you to another party who will provide you services due to a
                conflict of interest that cannot be managed appropriately by me.
              </p>
              <h4 className="heading-3">Related Parties</h4>
              <p>
                We have relationships with a number of related parties and
                associates. Details of any remuneration or possible conflicts
                these relationships will be included in the Credit Proposal
                Disclosure document provided to you.
              </p>
              <h4 className="heading-3">Shareholding</h4>
              <p>
                I may directly or indirectly hold shares in Oxygen, AFG or the
                lenders I recommend. Details of any material holdings will be
                included in the Credit Proposal Disclosure document provided to
                you. If my material holding does not enable me to act in more
                than one capacity, I will refer you to another party who will
                provide you services due to a conflict of interest that cannot
                be managed appropriately by me.
              </p>
              <h4 className="heading-3">Ownership Structures</h4>
              <p>
                I may act in other roles or capacities related to the credit
                assistance I provide to you. Each of business activities are
                separate from another however I may be paid remuneration in my
                other business activities as a result of the credit assistance
                we provide to you. Further details any will be included in the
                Credit Proposal Disclosure document I provide to you.
              </p>
              <p>
                Where I am unable to act in more than one capacity, I will refer
                you to another party who will provide you services that I am
                unable to due to a conflict of interest that cannot be managed
                appropriately by me.
              </p>
              <h4 className="heading-3">AFG & AFG Home Loans</h4>
              <p>
                AFG Home Loans Pty Ltd (including AFG Securities Pty Ltd) are
                wholly owned subsidiaries of AFG. AFG may receive additional
                remuneration for any white label AFG Home Loans or securitised
                AFG Securities products that are settled. The remuneration
                received is in addition to those amounts paid to AFG as a
                mortgage broker aggregator and/or licensee. Details of this
                remuneration will be disclosed in your AFG Home Loan
                documentation.
              </p>
              <p>
                Accurate as at publication. Our lenders change from time to
                time, with new lenders coming onboard and some leaving. Every
                Oxygen broker has accreditation with a wide variety of the
                lenders listed above, but usually not with every lender. If
                there is a particular lender you wish to use but is not listed,
                we are happy to see if we can add them to our list.
              </p>
              <h4 className="heading-3">Complaints and Resolutions</h4>
              <p>
                Irrespective of our status as a licensee, representative or
                credit representative, our reputation is built on matching the
                appropriate product(s) to the individual’s requirements. We go
                to great lengths to ensure satisfaction with our services and
                offerings.
              </p>
              <p>
                However, there may be instances from time to time, where
                applicants may be dissatisfied with the outcomes of our
                consultation process. If you have a complaint about the service
                that we provide, the following steps or avenues for resolution
                are available to you.
              </p>
              <p className="ml-5 custom-ml">
                <h6 className="heading-6">Step 1</h6>
                <p>
                  Most complaints arise from miscommunication and can usually be
                  fixed quickly. So, please contact your Oxygen broker first and
                  express about your concerns.
                </p>
                <h6 className="heading-6">Step 2</h6>
                <p>
                  If the issue is not satisfactorily resolved within 5 working
                  days by talking with your broker, we will apply our internal
                  complaints process to manage your complaint appropriately. In
                  this instance, the complaint will be internally escalated to
                  our Complaints Officer. You may also contact the Complaints
                  Officer directly via post, phone or email.
                </p>
                <p>
                  Note: In some instances your broker may also be fulfilling the
                  role of the Complaints Officer. This will not affect the
                  capacity to have your complaint dealt with appropriately. By
                  using our internal complaints process we hope to assist you to
                  resolve your complaint quickly and fairly. The maximum
                  timeframe in which to provide a written response to you is 30
                  days, although in pursuit of best practice and the reputation
                  of our organisation, we aim to resolve these issues in a much
                  shorter time frame
                </p>
                <h6 className="heading-6">Step 3</h6>
                <p>
                  Although we try hard to resolve a customer’s concern in the
                  most considerate and direct manner, if you are not completely
                  satisfied after the above steps have been attempted, you still
                  have other avenues available to resolve the dispute. This is
                  then managed externally and independently.
                </p>
                <p>
                  This external dispute resolution (EDR) process is available to
                  you, at no cost.
                </p>
              </p>
            </div>
            <div className="complaints-content-area mb-5">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-area">
                    <div className="complaints-info-card">
                      <h3>Oxygen Complaints Officer</h3>
                      <div className="info-text">
                        <img src={complaints} alt="complaints icon" />
                        <h5 className="info-label">Complaints Manager</h5>
                      </div>
                      <div className="info-text">
                        <img src={call} alt="call icon" />
                        <h5 className="info-label">1300 855 699</h5>
                      </div>
                      <div className="info-text">
                        <img src={mail} alt="mail icon" />
                        <a href="mailto:oxygen@oxygen.com.au">
                          complaints@oxygen.com.au
                        </a>
                      </div>
                      <div className="info-text location">
                        <img src={location} alt="location icon" />
                        <h5 className="info-label">
                          Level 12, 92 Pitt St Sydney NSW 2000
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-area">
                    <div className="complaints-info-card">
                      <h3>External Dispute Resolution</h3>
                      <div className="info-text">
                        <img src={complaints} alt="complaints icon" />
                        <h5 className="info-label">
                          Australian Financial Complaints Authority
                        </h5>
                      </div>
                      <div className="info-text">
                        <img src={call} alt="call icon" />
                        <h5 className="info-label">1800 931 678</h5>
                      </div>
                      <div className="info-text">
                        <img src={mail} alt="mail icon" />
                        <a href="mailto:info@afca.org.au">info@afca.org.au</a>
                      </div>
                      <div className="info-text location">
                        <img src={location} alt="location icon" />
                        <h5 className="info-label">
                          Authority GPO Box 3, Melb VIC 3001
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="complaints-content-area mb-5">
              <h4 className="heading-3">
                Oxygen Broking Services Privacy Disclosure and Consent
              </h4>
              <p>
                Each consent given in this document continues until withdrawn in
                writing.
              </p>
              <h4 className="heading-3">Privacy Policy</h4>
              <p>
                In this Privacy Policy, the expressions we, us and our includes
                Oxygen Broking Services Pty Ltd and our aggregator Australian
                Finance Group Ltd ABN 11 066 385 822 ('AFG') Australian Credit
                Licence 389087 and its subsidiaries.
              </p>
              <p className="ml-5 custom-ml">
                <h6 className="heading-6">
                  Our commitment to protect your privacy
                </h6>
                <p>
                  We understand how important it is to protect your personal
                  information. This document sets out our privacy policy
                  commitment in respect of personal information we hold about
                  you and what we do with that information. It also includes our
                  policy on the handling of credit reports and other credit
                  information.
                </p>
                <p>
                  During your relationship with us, we may tell you more about
                  how we handle your personal information in application forms
                  or terms and conditions, for example. You should read these
                  documents carefully.
                </p>
                <p>
                  We recognise that any personal information we collect about
                  you will only be used for the purposes we have collected it or
                  as allowed by law. It is important to us that you are
                  confident that any personal information we hold about you will
                  be treated in a way that ensures protection of your personal
                  information.
                </p>
                <p>
                  Our commitment in respect of personal information is to abide
                  by the Australian Privacy Principles for the protection of
                  personal information, as set out in the Privacy Act 1988 and
                  any other relevant laws.
                </p>
                <h6 className="heading-6">Personal information</h6>
                <p>
                  When we refer to personal information we mean information from
                  which your identity is reasonably apparent. This information
                  may include information or an opinion about you
                </p>
                <p>
                  The kinds of personal information we may collect about you
                  include your name, date of birth, address, email address,
                  phone number, account details, occupation, transaction history
                  and any other information we made need to identify you.
                </p>
                <p>
                  If you are applying for finance we may also collect the ages
                  and number of your dependants and cohabitants, the length of
                  time at your current address, your employment details and
                  proof of earnings and expenses, and your Tax File Number or
                  tax residency status. When you visit our websites or use our
                  mobile apps, we collect your location information, IP address,
                  mobile device and network information and any third party
                  sites you access. For more information on how we do this, see
                  the heading 'Cookies' below.
                </p>
                <p>
                  The personal information we collect and hold about you may
                  include credit information. Credit information is information
                  which is used to assess your eligibility to be provided with
                  finance and may include any finance that you have outstanding,
                  your repayment history in respect of those loans, and any
                  defaults. Usually, credit information is exchanged between
                  credit and finance providers and credit reporting bodies.
                </p>
                <p>
                  If you want to make purchases from or through us we will also
                  collect your payment information. We will also collect this
                  information if we need to make payments to you. If you apply
                  for employment with us we will collect information about your
                  work history and ask your referees about you.
                </p>
                <h6 className="heading-6">
                  Why we collect your personal information
                </h6>
                <p>
                  If you apply for finance with us, we collect personal
                  information for the purposes of assessing your application and
                  managing that finance (including designing and pricing our
                  products). We may also collect your personal information for
                  the purposes of direct marketing and managing our relationship
                  with you. From time to time we may offer you other products
                  and services.
                </p>
                <p>
                  If you apply to become a finance broker with us or apply for
                  employment with us, we will collect information about you to
                  assist us to decide whether to appoint you and to manage that
                  relationship, including to offer other services and products
                  to you
                </p>
                <p>
                  We collect payment information in order to process your
                  payments.
                </p>
                <p>
                  We also collect your personal information (including credit
                  information) to minimise risks and identify or investigate
                  fraud and other illegal activities and to comply with laws and
                  assist government or law enforcement agencies.
                </p>
                <p>
                  To enable us to maintain a successful business relationship
                  with you, we may disclose your personal information to other
                  organisations that provide products or services used or
                  marketed by us. See the heading 'Do we disclose your personal
                  information?' below for further information.
                </p>
                <h6 className="heading-6">
                  How do we collect your personal information?
                </h6>
                <p>
                  Where reasonable and practical we will collect your personal
                  information directly from you. We may also collect your
                  personal information from credit reporting bodies, finance
                  brokers and other people such as accountants, lawyers and
                  referees.
                </p>
                <h6 className="heading-6">
                  Do we disclose your personal information?
                </h6>
                <p>We may disclose your personal information:</p>
                <p>
                  <ol>
                    <li>
                      to existing or prospective funders or other intermediaries
                      in relation to your finance requirements;
                    </li>
                    <li>to guarantors and other security providers;</li>
                    <li>
                      to other organisations that are involved in managing or
                      administering your finance such as valuers, surveyors,
                      third party suppliers, printing and postal services, call
                      centres, lenders mortgage insurers, trade insurers,
                      recovery firms, debt
                    </li>
                    <li>collectors, lawyers and credit reporting bodies;</li>
                    <li>
                      to businesses we have a relationship with that may want to
                      market products to you;
                    </li>
                    <li>
                      to companies that provide information and infrastructure
                      systems to us;
                    </li>
                    <li>
                      to anybody who represents you, such as finance brokers,
                      lawyers and accountants;
                    </li>
                    <li>to anyone, where you have provided us consent;</li>
                    <li>
                      where we are required to do so by law, such as under the
                      Anti-Money Laundering and Counter Terrorism Financing Act
                      2006 or in accordance with a subpoena or summons issued by
                      a court;
                    </li>
                    <li>
                      to organisations involved in our funding arrangements such
                      as trustees, warehouse funders, investors, rating
                      agencies, agents, auditors or advisers, or any entity that
                      has an interest in our business; or
                    </li>
                    <li>
                      to your current or previous employer, referees or identity
                      verification services.
                    </li>
                  </ol>
                </p>
                <p>
                  Prior to disclosing any of your personal information to
                  another person or organisation, we will take all reasonable
                  steps to satisfy ourselves that:
                </p>
                <p>
                  <ol>
                    <li>
                      the person or organisation has a commitment to protecting
                      your personal information at least equal to our
                      commitment, or
                    </li>
                    <li>you have consented to us making the disclosure.</li>
                  </ol>
                </p>
                <p>
                  We use cloud storage to store personal information that we
                  hold. The cloud storage and the IT servers may be located
                  outside Australia.
                </p>
                <p>
                  We may disclose your personal information to overseas entities
                  that provide support functions to us (including in USA,
                  Canada, Malaysia, India, Ireland, the United Kingdom and the
                  Philippines). You may obtain more information about these
                  entities by contacting us.
                </p>
                <p>
                  New technologies and data analytic software allows us to
                  combine information we have about you and our other customers,
                  with data sources, such as third party websites or the
                  Australian Bureau of Statistics. We are able to analyse this
                  data to learn more about you and other customers, and how to
                  improve our products and service we provide to you. We may use
                  this combined data to help other businesses better understand
                  their customers, however we will not provide any personal
                  information about you unless we are legally required to.
                </p>
                <h6 className="heading-6">Credit information</h6>
                <p>
                  If you ask us for credit or choose to be a guarantor, we may
                  collect the following kinds of credit information and exchange
                  this information with credit reporting bodies and other
                  entities:
                </p>
                <p>
                  <ol>
                    <li>your identity information;</li>
                    <li>
                      credit liability information being information about your
                      existing finance which includes the name of the credit
                      provider, whether the credit provider holds an Australian
                      Credit Licence, the type of finance, the day the finance
                      is entered into, the terms and conditions of the finance,
                      the maximum amount of finance available, and the day on
                      which the finance was terminated;
                    </li>
                    <li>
                      repayment history information which is information about
                      whether you meet your repayments on time;
                    </li>
                    <li>
                      information about the type of finance that you are
                      applying for;
                    </li>
                    <li>default and payment information;</li>
                    <li>
                      if you have committed fraud or another serious credit
                      infringement; and
                    </li>
                    <li>court proceedings information.</li>
                  </ol>
                </p>
                <p>
                  We exchange this credit information for the purposes of
                  assessing your application for finance and managing that
                  finance. We also share your credit information with credit
                  reporting bodies so they can provide credit reports to others.
                  This credit information may be held by us in electronic form
                  on our secure servers and may also be held in paper form. We
                  may use cloud storage to store the credit information we hold
                  about you.
                </p>
                <p>
                  I/We hereby authorise you to receive Credit Information from
                  any lender about my/our credit affairs, and to provide any
                  relevant real estate agent, lawyer, conveyancer, agent or
                  person authorised by me access to my Credit Information, with
                  details of whether finance has been approved for us, and if it
                  has, the terms of that approval, including providing a copy of
                  any approval letter.
                </p>
                <p>
                  I/We appoint you as our agent and authorise you to obtain our
                  Credit Information (including both consumer and commercial
                  credit reporting and eligibility information) from a credit
                  reporting body on our behalf. You are authorised to use that
                  Credit Information to assist you to provide services,
                  including credit assistance, to me/us and to assist me/us to
                  apply for credit. I/We acknowledge that you consent to these
                  authorizations being provided in an electronic form (if
                  applicable).
                </p>
                <p>
                  In this document ‘Credit Information’ includes information
                  such as my/our identity information, the type, terms and
                  maximum amount of credit provided to me/us, repayment history
                  information, default information (including overdue payments),
                  court information, new arrangement information, personal
                  insolvency information, disciplinary proceedings, complaints,
                  delinquency, fraud investigations and details of any serious
                  credit infringements.
                </p>
                <p>
                  The credit reporting bodies we use are Equifax Pty Ltd,
                  Experian Australia Credit Services Pty Ltd and Illion
                  Australia Pty Ltd. You can download a copy of their privacy
                  policies at: equifax.com.au; experian.com.au; or
                  illion.com.au.
                </p>
                <h6 className="heading-6">
                  Receiving Information Electronically
                </h6>
                <p>
                  I/We consent to receiving credit assistance documentation and
                  loan application information electronically. I/We acknowledge
                  and agree that paper documents may no longer be given,
                  electronic communications must be regularly checked for
                  documents and this consent to receive electronic
                  communications may be withdrawn at any time.
                </p>
                <h6 className="heading-6">
                  Video or Audio Conference Recording
                </h6>
                <p>
                  I/We consent to you obtaining Personal Information, financial
                  information and Credit Information about me/us by way of video
                  conference and/or by way of audio conference for the purpose
                  of arranging or providing credit, providing credit assistance
                  or insuring credit and give my/our permission for that video
                  and/or audio conference to be recorded and provided to a
                  credit provider, insurer or government agency for this
                  purpose.
                </p>
                <h6 className="heading-6">Notifiable Data Breaches</h6>
                <p>
                  From February 2018, the Privacy Act 1988 includes a new
                  Notifiable Data Breaches ('NDB') scheme which requires us to
                  notify you and the Office of the Australian Information
                  Commissioner ('OAIC') of certain data breaches and recommend
                  steps you can take to limit the impacts of a breach (for
                  example, a password change).
                </p>
                <p>
                  The NDB scheme requires us to notify about a data breach that
                  is likely to result in serious harm to affected individuals.
                  There are exceptions where notification is not required. For
                  example, where we have already taken appropriate remedial
                  action that removes the risk of serious harm to any
                  individuals.
                </p>
                <p>
                  If we believe there has been a data breach that impacts your
                  personal information and creates a likely risk of serious
                  harm, we will notify you and the OAIC as soon as practicable
                  and keep in close contact with you about the nature of the
                  breach, the steps we are taking and what you can do to reduce
                  the impacts to your privacy. If you believe that any personal
                  information we hold about you has been impacted by a data
                  breach, you can contact us.
                </p>
                <h6 className="heading-6">Direct marketing</h6>
                <p>
                  From time to time we may use your personal information to
                  provide you with current information about finance offers you
                  may find of interest, changes to our organisation, or new
                  products or services being offered by us or any company with
                  whom we are associated.
                </p>
                <p>
                  If you do not wish to receive marketing information, you may
                  at any time decline to receive such information by emailing
                  us. If the direct marketing is by email you may also use the
                  unsubscribe function. We will not charge you for giving effect
                  to your request and will take all reasonable steps to meet
                  your request within a reasonable timeframe.
                </p>
                <h6 className="heading-6">
                  Updating your personal information
                </h6>
                <p>
                  It is important to us that the personal information we hold
                  about you, such as current home address, email address and
                  phone number, is accurate and up to date. During the course of
                  our relationship with you we may ask you to inform us if any
                  of your personal information has changed.
                </p>
                <p>
                  If you wish to make any changes to your personal information,
                  you may contact us. We will generally rely on you to ensure
                  the information we hold about you is accurate and complete.
                </p>
                <h6 className="heading-6">
                  Access and correction to your personal and credit information
                </h6>
                <p>
                  We will provide you with access to the personal and credit
                  information we hold about you. You may request access to any
                  of the personal information we hold about you at any time.
                </p>
                <p>
                  We may charge a fee for our costs of retrieving and supplying
                  the information to you. We will provide an estimate of this
                  cost so you can decide whether you would like to proceed.
                </p>
                <p>
                  We aim to respond within 30 days of receiving your request. We
                  may need to contact other entities to properly investigate
                  your request.
                </p>
                <p>
                  There may be situations where we are not required to provide
                  you with access to your personal information, for example, if
                  the information is commercially sensitive or relates to
                  existing or anticipated legal proceedings, or if your request
                  is vexatious.
                </p>
                <p>
                  An explanation will be provided to you if we deny you access
                  to the personal information we hold about you.
                </p>
                <p>
                  If any of the personal information we hold about you
                  (including credit information) is incorrect, inaccurate or out
                  of date you may request that we correct the information. If
                  appropriate we will correct the personal information. We will
                  not charge you a fee to correct your personal information. We
                  aim to provide you with details about whether we have
                  corrected the personal information within 30 days of receiving
                  your request.
                </p>
                <p>
                  We may need to consult with other entities as part of our
                  investigation.
                </p>
                <p>
                  If we refuse to correct personal information we will provide
                  you with our reasons for not correcting the information.
                </p>
                <h6 className="heading-6">Using government identifiers</h6>
                <p>
                  When we collect government identifiers, such as your tax file
                  number, we do not use or disclose this information other than
                  as required by law. We will never use a government identifier
                  to identify you.
                </p>
                <h6 className="heading-6">Business without identifying you</h6>
                <p>
                  In most circumstances it will be necessary for us to identify
                  you in order to successfully do business with you, however,
                  where it is lawful and practicable to do so, we will offer you
                  the opportunity of doing business with us without providing us
                  with personal information, for example, if you make general
                  inquiries about interest rates or current promotional offers.
                </p>
                <h6 className="heading-6">Sensitive information</h6>
                <p>
                  We will only collect sensitive information about you with your
                  consent except where otherwise allowed by law. Sensitive
                  information is personal information that includes information
                  relating to your racial or ethnic origin, political
                  persuasion, memberships in trade or professional associations
                  or trade unions, sexual preferences, criminal record, or
                  health or biometrics (for example, your fingerprints).
                </p>
                <h6 className="heading-6">
                  How safe and secure is your personal information that we hold?
                </h6>
                <p>
                  We will take reasonable steps to protect your personal
                  information by storing it in a secure environment. We may
                  store your personal information in paper and electronic form.
                  We will also take reasonable steps to protect any personal
                  information from misuse, loss and unauthorised access,
                  modification or disclosure.
                </p>
                <h6 className="heading-6">Complaints</h6>
                <p>
                  If you are dissatisfied with how we have dealt with your
                  personal information, or you have a complaint about our
                  compliance with the Privacy Act, you may contact us. We will
                  acknowledge your complaint within seven days. We aim to
                  provide you with a decision on your complaint within 30 days.
                  We will let you know if we will take longer than 30 days and
                  the relevant extension of time we will require to complete the
                  complaints process.
                </p>
                <p>
                  If you are dissatisfied with our response after you have been
                  through the internal complaints process, you may make a
                  complaint to our External Dispute Resolution Scheme, the
                  Australian Financial Complaints Authority (AFCA) which can be
                  contacted on either{" "}
                  <a href="https://www.afca.org.au">www.afca.org.au</a> or 1800
                  931 678 (free call); or the Office of the Australian
                  Information Commissioner (OAIC) which can be contacted on
                  either <a href="https://www.oaic.gov.au">www.oaic.gov.au</a>{" "}
                  or 1300 363 992.
                </p>
                <h6 className="heading-6">Notifiable matters</h6>
                <p>
                  The law requires us to advise you of 'notifiable matters' in
                  relation to how we may use your credit information. You may
                  request to have these notifiable matters (and this privacy
                  policy) provided to you in an alternative form.
                </p>
                <p>
                  We exchange your credit information with credit reporting
                  bodies. We use the credit information that we exchange with
                  the credit reporting body to assess your creditworthiness,
                  assess your application for finance and manage your finance.
                  If you fail to meet your payment obligations in relation to
                  any finance that we have provided or arranged or you have
                  committed a serious credit infringement then we may disclose
                  this information to a credit reporting body.
                </p>
                <p>
                  You have the right to request access to the credit information
                  that we hold about you and make a request for us to correct
                  that credit information if needed. Please see the heading
                  'Access and correction to your personal and credit
                  information' below.
                </p>
                <p>
                  Sometimes your credit information will be used by credit
                  reporting bodies for the purposes of 'pre-screening' credit
                  offers on the request of other credit providers. You can
                  contact the credit reporting body at any time to request that
                  your credit information is not used in this way.
                </p>
                <p>
                  You may contact the credit reporting body to advise them that
                  you believe that you may have been a victim of fraud. For a
                  period of 21 days after the credit reporting body receives
                  your notification the credit reporting body must not use or
                  disclose that credit information. You can contact any of the
                  following credit reporting bodies for more information:
                </p>
                <p>
                  <a href="https://www.equifax.com.au/">equifax.com.au</a>,{" "}
                  <a href="https://www.illion.com.au/">illion.com.au</a>, or{" "}
                  <a href="experian.com.au.">experian.com.au.</a>
                </p>

                <h6 className="heading-6">Cookies</h6>
                <p>
                  The information that we collect from you when you interact
                  with us online depends on the tasks you complete on this
                  website. When you visit and browse through the website, we
                  collect general information that is not capable of identifying
                  you for statistical and maintenance purposes that enables us
                  to continually evaluate the performance of this website.
                </p>
                <p>
                  This general information includes the number of users visiting
                  this website and the number of pages viewed as well as the
                  path taken through this website.
                </p>
                <p>
                  This information is used to generate aggregated, anonymous
                  information about how you and other users use this website and
                  its features. For instance, we collect information about which
                  pages on this website you visit most often, if you open or
                  read the communications we send, which advertisements you view
                  or interact with on this website or other websites on which
                  our advertisements appear, and if you receive any error
                  messages. The information collected may be used to improve
                  users' online experience generally, to collate statistics, or
                  customise your online experience by showing you content we
                  think you will like. We do not collect information that
                  identifies you personally; however, if you have a registration
                  account with us, the information collected by us using the
                  cookies may be associated with your registration account.
                </p>
                <p>
                  We also may allow third-party advertisers to place their
                  cookies on your device through their advertisements on this
                  website. The information these third-party cookies collect is
                  used to determine which third-party advertisements to display
                  on this website, how often to display them, and in which
                  locations of this websites the third-party advertisements
                  perform best. These third-party cookies do not collect
                  information that identifies you personally.
                </p>
                <p>
                  Any information, which is not capable of identifying you, is
                  collected through code embedded in the webpages of this
                  website. You cannot disable the code on these pages.
                </p>
                <p>
                  Other information, such as browser type, is included in a
                  'cookie' that is sent to your computer when you are completing
                  certain tasks on this website. A cookie contains bits of
                  information that enables our servers (i.e. the computers that
                  house this website) to identify and interact efficiently with
                  your computer. Cookies are designed to provide a better, more
                  customised website experience, and to make it easier for you
                  to use this website.
                </p>
                <p>
                  You can configure your browser to either accept all cookies,
                  reject all cookies or to notify you when a cookie is being
                  sent to your computer. The 'help function' on your browser
                  will provide you with details on how to change your browser
                  configurations. You will need to accept cookies in order to
                  use some functionality on this website. If you do not wish to
                  receive any cookies you may set your browser to refuse them.
                  However, your use of our website may be affected.
                </p>

                <h6 className="heading-6">Further information</h6>
                <p>
                  You may request further information about the way we manage
                  your personal or credit information by contacting us.
                </p>
                <h6 className="heading-6">Change in our privacy policy</h6>
                <p>
                  We are constantly reviewing all of our policies and attempt to
                  keep up to date with market expectations. Technology is
                  constantly changing, as is the law and market place practices.
                </p>
                <p>
                  As a consequence, we may change this privacy policy from time
                  to time or as the need arises. You may request this privacy
                  policy in an alternative form.
                </p>
                <div className="mb-5 pt-5">
                  <p>
                    This Privacy Policy was last updated on 10 December 2021.
                    Version 2.2
                  </p>
                </div>
              </p>
            </div>

            {/* <div className="complaints-content-area mb-5 pt-5">
              <h6 className="heading-6 text-center">
                Similar sections from this point below
              </h6>
            </div> */}
          </div>
        </div>
      </section>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Privacy
